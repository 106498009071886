import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'replaceAll'
})

export class ReplaceAllPipe implements PipeTransform {

  transform(value: string, pattern = '', replacement = ''): string {
    return typeof value === 'string' ? value.replaceAll(pattern, replacement) : value;
  }
}
