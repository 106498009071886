import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(value: object[], sortFunction?: any): any[] {
    return [...value].sort(sortFunction);
  }

}
