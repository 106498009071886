import { HttpClient, HttpContext } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EXTERNAL_HTTP_REQUEST } from '@core/auth/interceptors/api.interceptor';
import { SKIP_AUTH } from '@core/auth/interceptors/auth.interceptor';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(private http$: HttpClient) { }

  transform(url: string): Observable<string | ArrayBuffer | null> {
    return this.http$.get(url, {
      responseType: 'blob',
      context: new HttpContext().set(EXTERNAL_HTTP_REQUEST, true).set(SKIP_AUTH, true),
    }).pipe(
      switchMap(blob => new Observable<string | ArrayBuffer | null>(
        (observer => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            observer.next(reader.result);
          };
        })
      ))
    );
  }

}
