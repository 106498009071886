import { Pipe, PipeTransform } from '@angular/core';
import { MathUtil, ObjectUtil } from '@core/utils';

type Operation = 'sum' | 'max' | 'min' | 'average' | 'median' | 'size' | 'unique-size' | 'filter';
type ObjectItem = { [key: string]: any; };
type StatsArray = string[] | number[] | ObjectItem[];

@Pipe({
  standalone: true,
  name: 'arrayStats'
})

export class ArrayStatsPipe implements PipeTransform {

  transform(array: StatsArray, operation: Operation, key?: string, value?: any): number | number[] | object[] {

    const list = Array.isArray(array) ? array : [];

    switch (operation) {
      case 'sum': {
        const numbers = this.extractNumbers(list, key);
        return this.sum(numbers);
      }

      case 'average': {
        const numbers = this.extractNumbers(list, key);
        return MathUtil.roundToDecimal(this.sum(numbers) / numbers.length);
      }

      case 'median': {
        const numbers = this.extractNumbers(list, key);
        return this.median(numbers);
      }

      case 'max': {
        const numbers = this.extractNumbers(list, key);
        return Math.max(...numbers);
      }

      case 'min': {
        const numbers = this.extractNumbers(list, key);
        return Math.min(...numbers);
      }

      case 'size': {
        return list.length ?? 0;
      }

      case 'unique-size': {
        return this.getNumberOfUniqueItems(list as { [key: string]: any }[], key);
      }

      case 'filter': {
        if (!key) return [];
        return list.filter(item => (item as ObjectItem)[key] === value) as object[];
      }

      default:
        throw new Error(`Operation was not provided for array stats pipe`);
    }
  }

  private extractNumbers(array: StatsArray, key?: string): number[] {
    if (!Array.isArray(array)) {
      return [];
    }

    if ((array as number[]).every(el => typeof el === 'number')) {
      return array as number[];
    }

    if (key && (array as ObjectItem[]).every(el => ObjectUtil.isObject(el) && typeof (el as { [key: string]: any; })[key] === 'number')) {
      return (array as ObjectItem[]).map(el => (el as ObjectItem)[key]);
    }

    return [];
  }

  private getNumberOfUniqueItems(array: { [key: string]: any }[], key?: string): number {
    if (array.every(el => ObjectUtil.isObject(el))) {

      if (!key) {
        return 0;
      }

      const items = array.filter(item => item[key]).map(item => item[key]);
      return new Set([...items]).size;
    }

    return new Set([...array]).size
  }

  private sum(array: number[]): number {
    return array.reduce((sum, el) => sum + el, 0);
  }

  private median(array: number[]): number {
    const sorted = [...array].sort((a, b) => a - b);
    const middleIndex = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middleIndex - 1] + sorted[middleIndex]) / 2;
    }

    return sorted[middleIndex];
  }

}

