import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '@core/utils';
import { Observable, of, timer } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'dateToAge'
})
export class DateToAgePipe implements PipeTransform {
  transform(
    date: number | string,
  ): any {
    if (!date) {
      return '';
    }
    return new DateUtil(date).toAge();
  }
}
