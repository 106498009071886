import { Pipe, PipeTransform } from '@angular/core';
import { MathUtil } from '@core/utils';

type Operation = 'round' | 'round-to-decimal' | 'ceil-to-decimal';

@Pipe({
  name: 'mathRound',
  standalone: true
})
export class MathRoundPipe implements PipeTransform {

  transform(data: number, operation: Operation = 'round', places: number = 2): unknown {
    if (operation === 'round-to-decimal') {
      return MathUtil.roundToDecimal(data, places ?? 2);
    }

    if (operation === 'ceil-to-decimal') {
      return MathUtil.ceilToDecimal(data, places ?? 2);
    }

    return Math.round(data);
  }

}
