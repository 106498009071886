import { Pipe, PipeTransform } from '@angular/core';
import objectPath from 'object-path';

@Pipe({
  standalone: true,
  name: 'objectPath'
})
export class ObjectPathPipe implements PipeTransform {

  transform(value: object, path: string): unknown {
    return objectPath.get(value, path);
  }
}
