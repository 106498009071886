import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_LIMIT = 20;
const DEFAULT_TRAIL = '...';

@Pipe({
 name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

transform(value: string, limit = DEFAULT_LIMIT, trail = DEFAULT_TRAIL): string {
    return value.length > limit ? value.slice(0, Number(limit)) + trail : value;
   }
}
