import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const enum DateFormat {
  full = 'yyyy/MM/dd - HH:mm',
  short = 'yyyy/MM/dd',
}

@Pipe({
  standalone: true,
  name: 'faDate'
})

export class FaDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: string, type: 'full' | 'short' = 'full'): string | null {

    return this.datePipe.transform(value, this.getDateFormat(type));
  }

  private getDateFormat(type: string): DateFormat {
    switch (type) {
      case 'short':
        return DateFormat.short;
      case 'full':
      default:
        return DateFormat.full;
    }
  }
}
